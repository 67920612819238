import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/Auth.css';  // Import CSS for styling
import API_BASE_URL from '../config';

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // Added for success message
  const [role, setRole] = useState('User');  // Default role is "User"

  const handleSignup = (e) => {
    e.preventDefault();

    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');

    // Validate password length
    if (password.length < 8) {
      setErrorMessage('Password must be at least 8 characters long.');
      return;
    }

    // Prepare the payload to include all necessary details
    const payload = {
      firstName,
      lastName,
      email,
      password,
      companyName,
      role,
    };

    // console.log('Payload being sent to backend:', payload);

    axios
      .post(`${API_BASE_URL}/users/register`, payload, {
        headers: {
          'Content-Type': 'application/json',  // Set content type
        },
      })
      .then((response) => {
        // Check if the response status is 201 (Created) or any other successful status
        if (response.status === 201 || response.status === 200) {
          // console.log('Signup successful!');
          setSuccessMessage('Successfully registered. You will be emailed once you are ready to log in. Stay tuned, see you soon!');
        } else {
          setErrorMessage('Unexpected response. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Signup failed:', error);
        if (error.response && error.response.status === 409) {
          setErrorMessage('Email already exists. Try logging in or using another email.');
        } else if (error.response && error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message);  // Show error message from backend
        } else {
          setErrorMessage('Signup failed. Please try again.');
        }
      });
  };

  return (
    <div className="auth-container">
      <div className="signup-form">
        <div style={{ textAlign: 'center' }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/MyMyConnect-13-3.png`}
            alt="MyMy Connect Logo"
            style={{ width: '200px', height: 'auto', display: 'block', margin: '0 auto', marginBottom: '20px' }}
          />
          <h4 style={{ color: 'darkblue', fontWeight: 'bold' }}>Sign Up</h4>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && (
          <p className="success-message">
            <span style={{ color: 'green', fontWeight: 'bold' }}>Successfully Registered.</span>
            <span style={{ color: 'red', fontWeight: 'normal' }}> You will be emailed once you are ready to log in. Stay tuned, see you soon!</span>
          </p>
        )}
        <form onSubmit={handleSignup} className="form-table">
          <div className="form-row">
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Company:</label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Role:</label>
              <select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
          </div>

          <div className="form-button">
            <button type="submit" className="auth-button">Sign Up</button>
          </div>
        </form>
        <div className="login-link">
          <p>Already have an account? <Link to="/">Login here</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Signup;