import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import API_BASE_URL from '../config';
import axios from 'axios';

const TargetForm = ({ show, handleClose, targetToEdit, fetchTargets  }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [designation, setDesignation] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    // const [notes, setNotes] = useState('');
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [status, setStatus] = useState('Hot');
    const [category, setCategory] = useState('Friend');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Populate the form fields if editing a target
    useEffect(() => {
        if (targetToEdit) {
            // Populating fields if targetToEdit is passed for editing
            // console.log("Populating fields for Edit mode with target:", targetToEdit);
            // console.log("Status being set:", targetToEdit.status);  // Debug log
            setFirstName(targetToEdit.firstName);
            setLastName(targetToEdit.lastName);
            setCompany(targetToEdit.company);
            setDesignation(targetToEdit.designation);
            setPhone(targetToEdit.phone);
            setEmail(targetToEdit.email);
            setLinkedinUrl(targetToEdit.linkedinUrl);
            setStatus(targetToEdit.status);
            setCategory(targetToEdit.category);
            setCity(targetToEdit.city);
            setState(targetToEdit.state);
            setCountry(targetToEdit.country);
        } else {
            // Clear form for adding a new target
            // console.log("Clearing form fields for Add mode");
            setFirstName('');
            setLastName('');
            setCompany('');
            setDesignation('');
            setPhone('');
            setEmail('');
            setLinkedinUrl('');
            // setStatus('Hot');
            setCategory('Friend');
            setCity('');
            setState('');
            setCountry('');
        }
        setErrorMessage(''); // Reset any error messages
    }, [targetToEdit]);
    // console.log("Rendered status:", status);

    useEffect(() => {
        // console.log("Status after setting:", status);
    }, [status]);

    // Form validation
    const validateForm = () => {
        // Clear previous error messages
        setErrorMessage('');

        // Check if firstName is null or empty
        if (!firstName?.trim()) {
            setErrorMessage('First Name is required.');
            return false;
        }

        // Only validate email format if email is provided
        if (email?.trim()) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setErrorMessage('Please enter a valid email address.');
                return false;
            }
        }
        return true;
    };

    // Handle form submission for Add/Update
    const handleSaveTarget = () => {
        // console.log("handleSaveTarget method is called...");

        const token = sessionStorage.getItem('token');  // Retrieve the token from sessionStorage
        const tenantId = sessionStorage.getItem('tenantId');  // Retrieve the tenantId from sessionStorage
        const userId = sessionStorage.getItem('userId');

        // console.log("Token retrieved from sessionStorage:", token);
        // console.log("Tenant ID retrieved from sessionStorage:", tenantId);

        // Validate the form before submitting
        if (!validateForm()) {
            // console.log("Form validation failed");
            return;
        }

        // Create newTarget object for sending to the backend
        const newTarget = {
            firstName, lastName, company,
            designation, phone, email,
            linkedinUrl, status, category, city, state,
            country, tenantId, userId
        };

        // console.log("New target object being sent:", newTarget);

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        // console.log("Config for axios request:", config);

        // Determine if we are adding or updating based on targetToEdit
        const request = targetToEdit
            ? axios.put(`${API_BASE_URL}/targets/${targetToEdit.id}`, newTarget, config)
            : axios.post(`${API_BASE_URL}/targets`, newTarget, config);

        // console.log("Request type:", targetToEdit ? 'PUT (update)' : 'POST (create)');
        if (targetToEdit) {
            // console.log("Target to edit, ID:", targetToEdit.id);
        }

        request
            .then(() => {
                // console.log("Target saved successfully");
                fetchTargets();  // Refresh targets after adding/updating
                handleClose();   // Close the modal after save
            })
            .catch(error => {
                console.error('Error saving target:', error);
                if (error.response && error.response.status === 403) {
                    console.error('Access forbidden: Invalid or missing token');
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('tenantId');
                    sessionStorage.removeItem('userId');
                    window.location.href = '/login'; // Redirect to login if token is invalid
                } else if (error.message === 'Network Error') {
                    setErrorMessage('Network error: Please check your connection or server.' + error);
                } else {
                    setErrorMessage('An error occurred while saving the target.');
                }
            });
    };
    return (
        <Modal 
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            dialogClassName="modal-centered"
            style={{  margin: 'auto', padding: '10px' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{targetToEdit ? 'Update Target' : 'Add Target'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '10px' }}>
                {errorMessage && <div className="error-message" style={{ fontSize: '14px' }}>{errorMessage}</div>}
                <Form style={{ fontSize: '14px' }}>
                    <Row className="flex-wrap">
                        <Col style={{ flex: '0 0 calc(30ch + 4px)', marginRight: '4px' }}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    value={firstName || ''}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col style={{ flex: '0 0 4px' }}></Col>
                        <Col style={{ flex: '0 0 calc(30ch + 4px)' }}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    value={lastName || ''}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="flex-wrap">
                        <Col style={{ flex: '0 0 calc(30ch + 4px)', marginRight: '4px' }}>
                            <Form.Group>
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    value={company || ''}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col style={{ flex: '0 0 4px' }}></Col>
                        <Col style={{ flex: '0 0 calc(30ch + 4px)' }}>
                            <Form.Group>
                                <Form.Label>Designation</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    value={designation || ''}
                                    onChange={(e) => setDesignation(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="flex-wrap">
                        <Col style={{ flex: '0 0 calc(30ch + 4px)', marginRight: '4px' }}>
                            <Form.Group>
                                <Form.Label>LinkedIn URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    value={linkedinUrl || ''}
                                    onChange={(e) => setLinkedinUrl(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col style={{ flex: '0 0 4px' }}></Col>
                        <Col style={{ flex: '0 0 calc(30ch + 4px)' }}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    maxLength={30}
                                    value={email || ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="flex-wrap">
                        <Col style={{ flex: '0 0 15ch', marginRight: '4px' }}>
                            <Form.Group>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={15}
                                    value={phone || ''}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col style={{ flex: '0 0 4px' }}></Col>
                        <Col style={{ flex: '0 0 10ch', marginRight: '4px' }}>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={status || 'HOT'}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option value="HOT">HOT</option>
                                    <option value="WARM">WARM</option>
                                    <option value="COLD">COLD</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col style={{ flex: '0 0 4px' }}></Col>
                        <Col style={{ flex: '0 0 13ch' }}>
                            <Form.Group>
                                <Form.Label>Category</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={category || 'Target'}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value="Friend">Friend</option>
                                    <option value="Lead">Lead</option>
                                    <option value="Target">Target</option>
                                    <option value="Customer">Customer</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="flex-wrap">
                        <Col xs={12} sm={4}>
                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={city || ''}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={state || ''}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Col xs={12} sm={4}>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={country || ''}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        </Form>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'flex-start', padding: '10px' }}>
                <Button variant="secondary" onClick={handleClose} style={{ width: '20%', marginRight: '5ch' }}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveTarget} style={{ width: '25%' }}>
                    {targetToEdit ? 'Update Target' : 'Add Target'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TargetForm;

