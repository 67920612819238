import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Row, Col, Modal } from 'react-bootstrap';
import { Plus, Search, X, LogOut } from 'lucide-react';
import axios from 'axios';
import { FaLinkedin, FaEdit, FaBell } from 'react-icons/fa';
import CommunicationList from './CommunicationList';
import TargetForm from './TargetForm';
import ReminderModal from './ReminderModal';
import API_BASE_URL from '../config';
import '../styles/MobileTargetList.css';
import SearchModal from './SearchModal';

const MobileTargetList = () => {
    const [targets, setTargets] = useState([]);
    const [reminderCounts, setReminderCounts] = useState({});
    const [message, setMessage] = useState('');
    const [selectedTargetId, setSelectedTargetId] = useState(null);
    const [showCommunicationModal, setShowCommunicationModal] = useState(false);
    const [showTargetModal, setShowTargetModal] = useState(false);
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [targetToEdit, setTargetToEdit] = useState(null);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const tenantId = sessionStorage.getItem('tenantId');
    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');

    // Clear Search Handler
    const handleClearSearch = () => {
        axios
            .post(`${API_BASE_URL}/targets/filter`, { tenantId, userId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setTargets(response.data);
                setMessage(
                    response.data.length
                        ? `Number of connects found: ${response.data.length}`
                        : 'No connects found'
                );
                setSortConfig({ key: 'dueTouch', direction: 'ascending' });
            })
            .catch((error) => {
                console.error('Error fetching all targets:', error);
                setMessage('Failed to fetch targets. Please try again.');
            });
    };

    // Open Search Modal
    const handleSearchModalOpen = () => setShowSearchModal(true);
    const handleSearchModalClose = () => setShowSearchModal(false);

    // Search Functionality
    const handleSearch = (searchParams) => {
        axios
            .post(`${API_BASE_URL}/targets/filter`, { tenantId, userId, ...searchParams }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setTargets(response.data);
                setMessage(
                    response.data.length
                        ? `Number of connects found: ${response.data.length}`
                        : 'No connects found'
                );
            })
            .catch((error) => {
                console.error('Error fetching filtered targets:', error);
                setMessage('Failed to fetch targets. Please try again.');
            });
    };

    const handleReminderModalClose = () => setShowReminderModal(false);

    const handleCommunicationModalClose = () => setShowCommunicationModal(false);

    const handleTargetModalClose = () => {
        setShowTargetModal(false);
        setTargetToEdit(null);
    };

    const fetchReminderCounts = useCallback(() => {
        if (!tenantId || !userId || !token) {
            console.error('Missing tenantId, userId, or token.');
            return;
        }

        axios
            .get(`${API_BASE_URL}/reminders/todaysCounts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    tenantId,
                    userId,
                },
            })
            .then((response) => {
                setReminderCounts(response.data);
            })
            .catch((error) => console.error("Error fetching reminder counts:", error));
    }, [tenantId, userId, token]);

    const fetchTargets = useCallback((payload = {}) => {
        if (!tenantId || !userId || !token) {
            console.error('Missing tenantId, userId, or token.');
            return;
        }

        axios.post(`${API_BASE_URL}/targets/filter`, {
            tenantId,
            userId,
            ...payload,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const data = response.data;
                setTargets(data);
                setMessage(data.length ? `Number of connects found: ${data.length}` : 'No connects found');
            })
            .catch(error => {
                console.error('Error fetching targets:', error);
                setMessage('Failed to fetch targets. Please try again.');
            });
    }, [tenantId, userId, token]);

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('tenantId');
        window.location.href = '/';
    };

    const handleAddTarget = () => {
        setTargetToEdit(null);
        setShowTargetModal(true);
    };

    const handleEditTarget = (target) => {
        setTargetToEdit(target);
        setShowTargetModal(true);
    };

    // Sorting functionality
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedTargets = useMemo(() => {
        let sortableTargets = [...targets];
        if (sortConfig.key !== null) {
            sortableTargets.sort((a, b) => {
                let aValue, bValue;
    
                if (sortConfig.key === 'touchAge' || sortConfig.key === 'dueTouch') {
                    aValue = a[sortConfig.key] === '' || a[sortConfig.key] === '-' ? null : parseInt(a[sortConfig.key], 10) || 0;
                    bValue = b[sortConfig.key] === '' || b[sortConfig.key] === '-' ? null : parseInt(b[sortConfig.key], 10) || 0;
                } else if (sortConfig.key === 'reminder') {
                    aValue = reminderCounts[a.id] === undefined ? null : reminderCounts[a.id];
                    bValue = reminderCounts[b.id] === undefined ? null : reminderCounts[b.id];
                }
    
                if (aValue === null && bValue === null) return 0;
                if (aValue === null) return 1; // Push null to the end
                if (bValue === null) return -1;
    
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableTargets;
    }, [targets, reminderCounts, sortConfig]);

    useEffect(() => {
        fetchTargets();
        fetchReminderCounts();
        setSortConfig({ key: 'dueTouch', direction: 'ascending' });
    }, [fetchTargets, fetchReminderCounts]);

    return (
        <div className="mobile-target-list">
            <Row className="mb-3 align-items-center" style={{ margin: '0 10px' }}>
                <Col>
                    <h3 className="text-center mb-0">Target List</h3>
                </Col>
            </Row>

            <Row className="mb-3 align-items-center">
                <Col xs="auto" className="d-flex align-items-center">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/MyMyConnect-13-3.png`}
                        alt="Connectify Logo"
                        style={{
                            width: 'auto',
                            height: '40px',
                            objectFit: 'contain',
                            marginRight: '10px',
                        }}
                    />
                </Col>
                <Col className="d-flex align-items-right">
                    <div 
                        onClick={handleAddTarget} 
                        className="icon-action icon-add-search me-4"
                        role="button"
                        aria-label="Add target"
                    >
                        <Plus size={24} />
                    </div>
                    <div 
                        onClick={handleSearchModalOpen} 
                        className="icon-action icon-add-search me-4"
                        role="button"
                        aria-label="Search"
                    >
                        <Search size={24} />
                    </div>
                    <div 
                        onClick={handleClearSearch} 
                        className="icon-action icon-add-search me-4"
                        role="button"
                        aria-label="Clear search"
                    >
                        <X size={24} />
                    </div>

                    <div className="flex-grow-1"></div>

                    <div  
                        onClick={handleLogout} 
                        className="icon-logout ms-3"
                        role="button"
                        aria-label="Logout"
                    >
                        <LogOut size={24} />
                    </div>
                </Col>
            </Row>
            <p style={{ color: targets.length === 0 ? 'red' : 'black', fontWeight: 'bold', textAlign: 'center' }}>
                {message}
            </p>

            <Table striped bordered hover responsive className="mobile-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th 
                            className="clickable-link" 
                            onClick={() => requestSort('touchAge')}
                        >
                           LastC
                        </th>
                        <th 
                            className="clickable-link" 
                            onClick={() => requestSort('reminder')}
                        >
                            Rmdr
                        </th>
                        <th 
                            className="clickable-link" 
                            onClick={() => requestSort('dueTouch')}
                        >
                            NxtMt
                        </th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTargets.map(target => (
                        <tr key={target.id}>
                            <td
                                className="clickable-link"
                                onClick={() => {
                                    setSelectedTargetId(target.id);
                                    setShowCommunicationModal(true);
                                }}
                            >
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {target.linkedinUrl ? (
                                        <a
                                            href={target.linkedinUrl.startsWith('http')
                                                ? target.linkedinUrl
                                                : `https://${target.linkedinUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaLinkedin style={{ color: 'blue', cursor: 'pointer', marginRight: '5px' }} />
                                        </a>
                                    ) : (
                                        <FaLinkedin style={{ color: 'grey', cursor: 'default', marginRight: '5px' }} />
                                    )}
                                </span>
                                {target.firstName} {target.lastName}
                            </td>
                            <td className="wrap-text text-start">
                                {target.touchAge !== '' ? (
                                    <span className={(parseInt(target.touchAge, 10) <= -30) ? 'text-danger fw-bold' : ''}>
                                        {`${target.touchAge} d`}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </td>
                            <td>
                                <span
                                    className="bell-icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedTargetId(target.id);
                                        setShowReminderModal(true);
                                    }}
                                >
                                    <FaBell className="bell-icon-size" />
                                    {reminderCounts[target.id] > 0 && (
                                        <span className="reminder-count">
                                            {reminderCounts[target.id]}
                                        </span>
                                    )}
                                </span>
                            </td>
                            <td className="wrap-text text-start">
                                {target.dueTouch !== '' ? (
                                    <span className={(parseInt(target.dueTouch, 10) > -5 && parseInt(target.dueTouch, 10) <= 3) ? 'text-danger fw-bold' : ''}>
                                        {`${target.dueTouch} d`}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </td>
                            <td>
                                <FaEdit
                                    className="action-icon"
                                    onClick={() => handleEditTarget(target)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <TargetForm
                show={showTargetModal}
                handleClose={handleTargetModalClose}
                targetToEdit={targetToEdit}
                fetchTargets={fetchTargets}
            />

            <Modal show={showCommunicationModal} onHide={handleCommunicationModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Communication History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTargetId && (
                        <CommunicationList
                            targetId={selectedTargetId}
                            handleCommunicationModalClose={handleCommunicationModalClose}
                        />
                    )}
                </Modal.Body>
            </Modal>
            <ReminderModal
                show={showReminderModal}
                handleClose={handleReminderModalClose}
                targetId={selectedTargetId}
            />
            <SearchModal
                show={showSearchModal}
                handleClose={handleSearchModalClose}
                handleSearch={handleSearch}
            />
        </div>
    );
};

export default MobileTargetList;