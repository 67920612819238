import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import API_BASE_URL from "../config";

const ReminderModal = ({ show, handleClose, targetId }) => {
  const [reminderDate, setReminderDate] = useState(new Date());
  const [reminderTime, setReminderTime] = useState("12:00 AM");
  const [event, setEvent] = useState("");
  const [category, setCategory] = useState("Normal");
  const [reminders, setReminders] = useState([]);

  const token = sessionStorage.getItem("token");
  const tenantId = sessionStorage.getItem("tenantId");
  const userId = sessionStorage.getItem("userId");
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Fetch reminders for the selected target
  const fetchReminders = useCallback(() => {
    if (!token || !tenantId || !userId || !targetId) {
      console.error("Missing token, tenantId, userId, or targetId.");
      return;
    }

    axios
      .get(`${API_BASE_URL}/reminders/${targetId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenantId,
          userId,
        },
      })
      .then((response) => {
        setReminders(response.data);
      })
      .catch((error) => console.error("Error fetching reminders:", error));
  }, [token, tenantId, userId, targetId]);

  // Add a new reminder
  const handleAddReminder = () => {
    // Convert reminderTime to 24-hour format first
    const [timePart, modifier] = reminderTime.split(" "); // e.g. "2:00 PM"
    const [hours, minutes] = timePart.split(":");
    let hh = parseInt(hours, 10);

    const modifierUpper = modifier.toUpperCase();
    if (modifierUpper === "PM" && hh !== 12) {
      hh += 12;
    } else if (modifierUpper === "AM" && hh === 12) {
      hh = 0;
    }

    const combinedDateTime = new Date(
      reminderDate.getFullYear(),
      reminderDate.getMonth(),
      reminderDate.getDate(),
      hh,
      minutes
    );

    const payload = {
      targetId,
      reminderDate: combinedDateTime.toISOString(),
      eventDetails: event,
      category,
      tenantId,
      userId,
    };

    axios
      .post(`${API_BASE_URL}/reminders`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        fetchReminders(); // Refresh the list
        setReminderDate(new Date());
        setReminderTime("12:00 AM");
        setEvent("");
        setCategory("Normal");
      })
      .catch((error) => console.error("Error adding reminder:", error));
  };

  // Delete a reminder
  const handleDeleteReminder = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this reminder?");
    if (!confirmDelete) return;

    if (!token) {
      console.error("Missing token in session storage.");
      return;
    }

    axios
      .delete(`${API_BASE_URL}/reminders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenantId,
          userId,
        },
      })
      .then(() => {
        fetchReminders(); // Refresh the list
      })
      .catch((error) => console.error("Error deleting reminder:", error));
  };

  const calculateDueDays = (reminderDate) => {
    const currentDate = new Date(); // Local system date
    const eventDate = new Date(reminderDate); // Reminder date from backend

    // Normalize both dates to the start of their day (midnight)
    const currentStartOfDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const eventStartOfDay = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate()
    );

    const timeDiff = eventStartOfDay - currentStartOfDay;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff === 0) {
      return <span style={{ color: "red", fontWeight: "bold" }}>TODAY</span>;
    }

    return `${daysDiff} days`;
  };

  useEffect(() => {
    if (show) {
      fetchReminders();
    }
  }, [show, fetchReminders]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Reminder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="d-flex flex-wrap">
          <div className="d-flex flex-wrap">
  <Form.Group style={{ flex: "0 0 10ch", marginRight: "5ch", marginBottom: "10px" }}>
    <DatePicker
      selected={reminderDate}
      onChange={(date) => setReminderDate(date)}
      dateFormat="MM/dd/yyyy"
      className="form-control"
      placeholderText="Event Date"
      style={{ width: "100%" }}
    />
  </Form.Group>
  <Form.Group style={{ flex: "0 0 8ch", marginBottom: "10px" }}>
    <Form.Control
      type="text"
      value={reminderTime}
      onChange={(e) => setReminderTime(e.target.value)}
      placeholder="12:00 AM"
      maxLength={8}
      style={{ width: "100%" }}
    />
  </Form.Group>
</div>
          </div>
          <div className="d-flex flex-wrap align-items-center mt-3">
            <Form.Group style={{ flex: "1", marginRight: "5px", marginBottom: "10px" }}>
              <Form.Control
                type="text"
                value={event}
                onChange={(e) => setEvent(e.target.value)}
                placeholder="Event Details"
                maxLength={40}
                style={{ width: "calc(100% - 70px)" }}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleAddReminder}
              style={{ width: "60px", marginLeft: "10px", marginBottom: "10px" }}
            >
              Add
            </Button>
          </div>
        </Form>
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Event</th>
              <th>Category</th>
              <th>Due Days</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reminders.map((reminder) => (
              <tr key={reminder.id}>
                <td>
                  {new Date(reminder.reminderDate).toLocaleDateString("en-US", { timeZone: userTimezone })}
                </td>
                <td>
                  {new Date(reminder.reminderDate).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                    timeZone: userTimezone,
                  })}
                </td>
                <td>{reminder.eventDetails}</td>
                <td style={{ color: reminder.category === "Critical" ? "red" : "black" }}>
                  {reminder.category}
                </td>
                <td>{calculateDueDays(reminder.reminderDate)}</td>
                <td>
                  <FaTrash
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => handleDeleteReminder(reminder.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ReminderModal;