import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CommunicationList = ({ targetId, handleCommunicationModalClose }) => {
    const [communications, setCommunications] = useState([]);
    const [followUpDate, setFollowUpDate] = useState(null);
    const [eventDate, setEventDate] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [editingCommId, setEditingCommId] = useState(null); // For tracking the communication being edited
    const navigate = useNavigate();
    const tenantId = sessionStorage.getItem('tenantId');
    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');
    const [errorMessage, setErrorMessage] = useState('');

    const fetchCommunications = useCallback(() => {
        if (!tenantId || !token) {
            console.error("Missing tenantId or token.");
            return;
        }

        axios.get(`${API_BASE_URL}/targets/${targetId}/communications`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                const sortedCommunications = response.data.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
                setCommunications(sortedCommunications);
                setFollowUpDate(sortedCommunications[0]?.nextFollowUpDate || null);
            })
            .catch(error => console.error('Error fetching communications:', error));
    }, [targetId, token, tenantId]);

    useEffect(() => {
        if (tenantId && token) {
            fetchCommunications();
        } else {
            console.error("Tenant ID or token not found in sessionStorage.");
        }
    }, [fetchCommunications, tenantId, token, targetId]);

    const handleSaveCommunication = () => {
        const today = new Date();

        // Validation for Event Date (should not be in the future)
        if (eventDate > today) {
            setErrorMessage('Event Date should not be a future date.');
            return;
        }

        // Validation for Follow-Up Date (should not be in the past)
        if (followUpDate && followUpDate < today) {
            setErrorMessage('Follow-Up Date should not be a past date.');
            return;
        }

        if (!followUpDate && !notes.trim()) {
            setErrorMessage('Please provide at least follow-up date or notes.');
            return;
        }

        setErrorMessage('');
        // console.log('eventDate: ',eventDate,' followUpDate',followUpDate);

        const communicationPayload = {
            id: editingCommId,
            eventDate,
            followUpDate,
            notes,
            targetId,
            tenantId,
            userId
        };

        const url = `${API_BASE_URL}/targets/${targetId}/communications`;

        axios.post(url, communicationPayload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(() => {
                fetchCommunications();
                setEventDate(null); // Reset eventDate
                setFollowUpDate(null); // Clear followUpDate
                setNotes('');
                setEditingCommId(null); // Reset editing state
            })
            .catch(error => console.error('Error saving communication:', error));
    };

    const handleEditCommunication = (comm) => {
        setEditingCommId(comm.id);
        setEventDate(new Date(comm.eventDate));
        setNotes(comm.notes);
    };

    return (
        <div>
            <Form>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
                    <Form.Group style={{ flex: '1', marginRight: '10px', position: 'relative' }}>
                        <Form.Label>Date</Form.Label>
                        <DatePicker
                            selected={eventDate}
                            onChange={(date) => setEventDate(date)}
                            dateFormat="MM/dd/yyyy"
                            popperPlacement="bottom-start"
                        />
                    </Form.Group>
                    <Form.Group style={{ flex: '1', position: 'relative' }}>
                        <Form.Label>Follow-Up</Form.Label>
                        <DatePicker
                            selected={followUpDate}
                            onChange={(date) => setFollowUpDate(date)}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Follow-up"
                            popperPlacement="bottom-start"
                        />
                    </Form.Group>
                </div>
                {errorMessage && (
                    <div style={{ color: 'red', marginBottom: '10px', fontWeight: 'bold' }}>
                        {errorMessage}
                    </div>
                )}
                <Form.Group>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </Form.Group>
                <Form.Text className="text-muted">max of 1000 chars</Form.Text>
                <div style={{ marginTop: '20px' }}>
                    <Button variant="primary" onClick={handleSaveCommunication}>
                        {editingCommId ? 'Update' : 'Save'}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setEventDate(null); // Reset eventDate
                            setFollowUpDate(null); // Clear followUpDate
                            handleCommunicationModalClose();
                            navigate('/targets', { state: { refresh: true } });
                        }}
                        style={{ marginLeft: '10px' }}
                    >
                        Back
                    </Button>
                </div>
            </Form>

            <Table striped bordered hover style={{ marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Communication</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {communications.length > 0 ? (
                        communications.map((comm) => (
                            <tr key={comm.id}>
                                <td>{new Date(comm.eventDate).toLocaleDateString()}</td>
                                <td>{comm.notes}</td>
                                <td>
                                    <Button variant="warning" size="sm" onClick={() => handleEditCommunication(comm)}>
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">No communication history available.</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default CommunicationList;