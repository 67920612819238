import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Home() {
    const paragraph1 = 'Connecting with people is essential for our well-being, both personally and professionally. In a world full of busy schedules and digital distractions, meaningful interactions often become scarce. <br /><br /> Yet, maintaining genuine connections can help us feel understood, supported, and valued. Whether it’s catching up with an old friend, sharing a hobby with a neighbor, or reaching out to a colleague, each connection broadens our perspective and enriches our lives. <br /><br /> The warmth and trust we gain from these relationships can help reduce stress, improve self-esteem, and create a more positive outlook on life.';
    const paragraph2 = 'MyMy Connect streamlines the process of maintaining meaningful relationships by providing timely reminders and a simple interface for recording key insights from each interaction. <br /> <br /> Instead of relying on memory or scattered notes, MyMy Connect ensures that you’re prompted to reach out just as someone crosses your mind, eliminating the chance of missing that important follow-up call or friendly check-in. <br /> <br /> Each reminder is tailored to help you stay engaged, making sure you never feel overwhelmed or lose track of who you wanted to connect with next.';
    const [featuresOpen, setFeaturesOpen] = useState(false);
    const [pricingOpen, setPricingOpen] = useState(false);

    return (
        <div>
            {/* Navigation Bar */}
            <nav style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#f8f9fa',
                borderBottom: '1px solid #ddd',
                padding: '10px 20px',
                fontFamily: 'sans-serif'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/MyMyConnect-13-3.png`}
                        alt="MyMy Connect Logo"
                        style={{ height: '50px', width: 'auto', objectFit: 'contain' }}
                    />

                    <div className="features-menu">
                        <span onClick={() => { setFeaturesOpen(!featuresOpen); setPricingOpen(false); }}>Features</span>
                        <div className={`dropdown-content ${featuresOpen ? 'show' : ''}`}>
                            <Link to="/features/feature1">Feature 1</Link>
                            <Link to="/features/feature2">Feature 2</Link>
                        </div>
                    </div>
                    <div className="features-menu">
                        <span onClick={() => { setPricingOpen(!pricingOpen); setFeaturesOpen(false); }}>Pricing</span>
                        <div className={`dropdown-content ${pricingOpen ? 'show' : ''}`}>
                            <Link to="/pricing/pricing1">Individual</Link>
                            <Link to="/pricing/pricing2">Business</Link>
                        </div>
                    </div>



                </div>
                <div style={{ display: 'flex', gap: '15px' }}>
                    <Link to="/login" style={{ textDecoration: 'none', fontWeight: 'bold', color: 'blue', fontSize: '14pt'}}>Login</Link>
                    <Link to="/signup" style={{ textDecoration: 'none', fontWeight: 'bold', color: 'blue', fontSize: '14pt'}}>Sign Up</Link>
                </div>
            </nav>

            {/* Four Column Layout */}
            <div style={{ padding: '20px', fontFamily: 'sans-serif' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            {/* Column 1: Image */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/Connect1.webp`} alt="Column 1" style={{ width: '300px', height: '300px' }} />
                            </td>
                            {/* Column 2: 2000 char text in 3 paragraphs */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}
                                dangerouslySetInnerHTML={{ __html: paragraph1 }}>
                            </td>
                            {/* Column 3: Image */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/Connect2.webp`} alt="Column 3" style={{ width: '300px', height: '300px' }} />
                            </td>
                            {/* Column 4: 2000 char text in 3 paragraphs */}
                            <td style={{ width: '25%', verticalAlign: 'top', whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{ __html: paragraph2 }}>
                            </td>
                        </tr>
                        <tr>
                            {/* Column 1: Image */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/Connect3.webp`} alt="Column 1" style={{ width: '300px', height: '300px' }} />
                            </td>
                            {/* Column 2: 2000 char text in 3 paragraphs */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}
                                dangerouslySetInnerHTML={{ __html: paragraph1 }}>
                            </td>
                            {/* Column 3: Image */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/Connect4.webp`} alt="Column 3" style={{ width: '300px', height: '300px' }} />
                            </td>
                            {/* Column 4: 2000 char text in 3 paragraphs */}
                            <td style={{ width: '25%', verticalAlign: 'top' }}
                                dangerouslySetInnerHTML={{ __html: paragraph2 }}>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Home;