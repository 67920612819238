import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const SearchModal = ({ show, handleClose, handleSearch }) => {
    const [searchParams, setSearchParams] = useState({
        firstName: '',
        lastName: '',
        company: '',
        designation: '',
        email: '',
        status: '',
        category: '',
        touchAge: '',
        dueTouch: '',
        city: '',
        state: '',
        country: '',
        reminderDateFilter: '',
    });



    const handleSearchInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prev) => {
            const updated = { ...prev, [name]: value };
            // console.log("Updated searchParams:", updated); // Log updated state
            return updated;
        });
    };

    const executeSearch = () => {
        // console.log("SearchModal Params:", searchParams); // Log the latest state
        handleSearch(searchParams); // Pass searchParams to the parent
        handleClose(); // Close the modal
    };
    // Add this function above the return statement
    const handleSubmit = (e) => {
        e.preventDefault();
        executeSearch();
    };

    return (

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Search Targets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    placeholder="Enter First Name"
                                    name="firstName"
                                    value={searchParams.firstName}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    placeholder="Enter Last Name"
                                    name="lastName"
                                    value={searchParams.lastName}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                    placeholder="Enter Company"
                                    name="company"
                                    value={searchParams.company}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control
                                    placeholder="Enter Designation"
                                    name="designation"
                                    value={searchParams.designation}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                    name="status"
                                    value={searchParams.status}
                                    onChange={handleSearchInputChange}
                                >
                                    <option value="">Select Status</option>
                                    <option value="Hot">Hot</option>
                                    <option value="Warm">Warm</option>
                                    <option value="Cold">Cold</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Category</Form.Label>
                                <Form.Select
                                    name="category"
                                    value={searchParams.category}
                                    onChange={handleSearchInputChange}
                                >
                                    <option value="">Select Category</option>
                                    <option value="Friend">Friend</option>
                                    <option value="Target">Target</option>
                                    <option value="Lead">Lead</option>
                                    <option value="Customer">Customer</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Touch Age</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="e.g., >=25"
                                    name="touchAge"
                                    value={searchParams.touchAge}
                                    onChange={handleSearchInputChange}
                                    maxLength={10} // Keep text box compact
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Due Touch</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="e.g., <15"
                                    name="dueTouch"
                                    value={searchParams.dueTouch}
                                    onChange={handleSearchInputChange}
                                    maxLength={10} // Keep text box compact
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    placeholder="Enter Email"
                                    name="email"
                                    value={searchParams.email}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    placeholder="City"
                                    name="city"
                                    value={searchParams.city}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    placeholder="State"
                                    name="state"
                                    value={searchParams.state}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    placeholder="Country"
                                    name="country"
                                    value={searchParams.country}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Reminder Due</Form.Label>
                                <Form.Control
                                    placeholder="Reminder Due < > >= <= date "
                                    name="reminderDateFilter"
                                    value={searchParams.reminderDateFilter}
                                    onChange={handleSearchInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
     
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Back
                    </Button>
                    <Button variant="primary" type="submit">
                        Search
                    </Button>
                </Modal.Footer>
                </Form>
                </Modal.Body>
            </Modal>
    );
};

export default SearchModal;