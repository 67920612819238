const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000/api"; // Fallback URL
console.log("API Base URL:", API_BASE_URL); // Debugging line
export default API_BASE_URL;

// src/config.js
console.log("Current Environment:", process.env.NODE_ENV); // Debugging line
// alert(`Current Environment: ${process.env.NODE_ENV}`); 
// const API_BASE_URL = process.env.NODE_ENV === 'production'
//     ? "https://www.mymyconnect.com:8443/api"  // Production URL
//     : "http://localhost:8443/api";            // Development URL

// export default API_BASE_URL;