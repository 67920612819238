import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/MobileApp.css'; 
import API_BASE_URL from '../config';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [tokenSet, setTokenSet] = useState(false); // New state to track if the token is set
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      alert('Please enter a valid email.');
      return;
    }

    axios.post(`${API_BASE_URL}/users/login`, { email, password }, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((response) => {
        const { token, user } = response.data;

        if (token && user) {
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('tenantId', user.tenantId);
          sessionStorage.setItem('userId', user.userId);
          setTokenSet(true);
        } else {
          alert('Invalid response from the server.');
        }
      })
      .catch((error) => {
        alert('Login failed. Please try again.');
        console.error('Login error:', error);
      });
  };

  useEffect(() => {
    if (tokenSet) navigate('/targets');
  }, [tokenSet, navigate]);

  return (
    <div className="auth-container">
      <div className="login-wrapper">
        <div className="logo-container">
          <img
            src={`${process.env.PUBLIC_URL}/images/MyMyConnect-13-3.png`}
            alt="Connectify Logo"
            style={{ width: '50%', height: 'auto' }} // Inline CSS for scaling
          />
        </div>

        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength="60"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength="60"
              required
            />
            <div>
              <label htmlFor="show-password" style={{ marginLeft: '8px' }}>Show Password</label>
              <input
                type="checkbox"
                id="show-password"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
            </div>
          </div>

          <button type="submit" className="login-button">Login</button>
        </form>

        <div className="signup-link">
          <Link to="/signup">
            Don't have an account? Sign up here!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;