// App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import SignUp from './components/SignUp';
import TargetList from './components/TargetList';
import MobileTargetList from './components/MobileTargetList';
import MobileLogin from './components/MobileLogin';
import CommunicationList from './components/CommunicationList';
import useDeviceType from './hooks/useDeviceType';
import Home from './components/Home';
import Pricing from './components/Pricing';
import FeatureOne from './components/FeatureOne';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isMobile = useDeviceType();

  const token = sessionStorage.getItem('token');

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
      if (location.pathname === '/login') {
        navigate('/targets');
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [token, navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={isMobile ? <MobileLogin /> : <Home />} />
      <Route path="/features/:feature" element={<FeatureOne />} />
      <Route path="/pricing/:type" element={<Pricing />} />
      <Route
        path="/login"
        element={isMobile ? <MobileLogin /> : <Login />}
      />
      <Route path="/signup" element={<SignUp />} />

      {/* Private routes */}
      <Route
        path="/targets"
        element={
          isAuthenticated ? (isMobile ? <MobileTargetList /> : <TargetList />) : <Login />
        }
      />
      <Route
        path="/targets/communicationlist/:targetId"
        element={isAuthenticated ? <CommunicationList /> : <Login />}
      />
    </Routes>
  );
}

export default App;