// FeatureOne.js
import React from 'react';

function FeatureOne() {
  return (
    <div>
      <h1>Feature Page</h1>
      <p>This is a test page for Features.</p>
    </div>
  );
}

export default FeatureOne;