import { useState, useEffect } from 'react';

function useDeviceType() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
        handleResize(); // Initial check when component mounts
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
}

export default useDeviceType;