import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Button, Row, Col, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaLinkedin, FaEdit, FaTrash, FaBell } from 'react-icons/fa';
import TargetForm from './TargetForm';
import CommunicationList from './CommunicationList';
import ReminderModal from './ReminderModal';
import API_BASE_URL from '../config';
import '../App.css';
import '../styles/ReminderModal.css';
import SearchModal from './SearchModal';

const TargetList = () => {
    const [targets, setTargets] = useState([]);
    const [reminderCounts, setReminderCounts] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [targetToEdit, setTargetToEdit] = useState(null);
    const tenantId = sessionStorage.getItem('tenantId');
    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');
    const [message, setMessage] = useState('');
    const [selectedTargetId, setSelectedTargetId] = useState(null);
    const [showCommunicationModal, setShowCommunicationModal] = useState(false);
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })

    const handleSearchModalOpen = () => setShowSearchModal(true);
    const handleSearchModalClose = () => setShowSearchModal(false);

    const handleSearch = (searchParams) => {
        // Use the searchParams to filter targets
        if (!token) {
            console.error("Authorization token is missing.");
            setMessage('Authorization error. Please log in again.');
            return;
        }

        axios
            .post(`${API_BASE_URL}/targets/filter`, searchParams, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setTargets(response.data);
                setMessage(
                    response.data.length
                        ? `Number of connects found: ${response.data.length}`
                        : 'No connects found'
                );
            })
            .catch((error) => {
                console.error('Error fetching filtered targets:', error);
                setMessage('Failed to fetch targets. Please try again.');
            });
    };

    const handleClearSearch = () => {
        if (!tenantId || !userId || !token) {
            console.error('Missing tenantId, userId, or token.');
            return;
        }

        axios
            .post(`${API_BASE_URL}/targets/filter`, { tenantId, userId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setTargets(response.data);
                setMessage(
                    response.data.length
                        ? `Number of connects found: ${response.data.length}`
                        : 'No connects found'
                );
                setSortConfig({ key: 'dueTouch', direction: 'ascending' });
            })
            .catch((error) => {
                console.error('Error fetching all targets:', error);
                setMessage('Failed to fetch targets. Please try again.');
            });
    };

    const handleReminderModalClose = () => setShowReminderModal(false);
    const handleCommunicationModalClose = () => setShowCommunicationModal(false);
    const handleAddTarget = () => {
        setTargetToEdit(null);
        setShowModal(true);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('tenantId');
        window.location.href = '/';
    };

    const handleEditTarget = (target, e) => {
        setTargetToEdit(target);
        // setClickPosition({ x: e.clientX, y: e.clientY }); // Capture click position
        setShowModal(true);
    };

    const handleDeleteTarget = (targetId) => {
        if (window.confirm('Are you sure you want to delete this target? This action cannot be undone.')) {
            axios.delete(`${API_BASE_URL}/targets/${targetId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(() => {
                    fetchTargets();
                    setMessage('Target deleted successfully.');
                })
                .catch((error) => {
                    console.error('Error deleting target:', error);
                    setMessage('Failed to delete target.');
                });
        }
    };

    const fetchTargets = useCallback((payload = {}) => {
        if (!tenantId || !userId || !token) {
            console.error('Missing tenantId, userId, or token.');
            return;
        }

        axios.post(`${API_BASE_URL}/targets/filter`, {
            tenantId,
            userId,
            ...payload,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setTargets(response.data);
                setMessage(response.data.length ? `Number of connects found: ${response.data.length}` : 'No connects found');
            })
            .catch((error) => {
                console.error('Error fetching targets:', error);
                setMessage('Failed to fetch targets. Please try again.');
            });
    }, [tenantId, userId, token]);

    const fetchReminderCounts = useCallback(() => {
        if (!tenantId || !userId || !token) {
            console.error('Missing tenantId, userId, or token.');
            return;
        }

        axios
            .get(`${API_BASE_URL}/reminders/todaysCounts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    tenantId,
                    userId,
                },
            })
            .then((response) => {
                setReminderCounts(response.data);
            })
            .catch((error) => console.error("Error fetching reminder counts:", error));
    }, [tenantId, userId, token]);

    useEffect(() => {
        fetchTargets();
        fetchReminderCounts();
        setSortConfig({ key: 'dueTouch', direction: 'ascending' });
    }, [fetchTargets, fetchReminderCounts]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedTargets = useMemo(() => {
        let sortableTargets = [...targets];
        if (sortConfig.key !== null) {
            sortableTargets.sort((a, b) => {
                let aValue, bValue;
    
                if (sortConfig.key === 'touchAge' || sortConfig.key === 'dueTouch') {
                    aValue = a[sortConfig.key] === '' || a[sortConfig.key] === '-' ? null : parseInt(a[sortConfig.key], 10) || 0;
                    bValue = b[sortConfig.key] === '' || b[sortConfig.key] === '-' ? null : parseInt(b[sortConfig.key], 10) || 0;
                } else if (sortConfig.key === 'reminder') {
                    aValue = reminderCounts[a.id] === undefined ? null : reminderCounts[a.id];
                    bValue = reminderCounts[b.id] === undefined ? null : reminderCounts[b.id];
                }
    
                if (aValue === null && bValue === null) return 0;
                if (aValue === null) return 1; // Push null to the end
                if (bValue === null) return -1;
    
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableTargets;
    }, [targets, reminderCounts, sortConfig]);

    return (
        <div>
            <h3 align="center">Target List</h3>
            <Row className="mb-3 align-items-center" style={{ margin: '0 10px' }}>
                <Col xs="auto" className="d-flex align-items-center">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/MyMyConnect-13-3.png`}
                        alt="Connectify Logo"
                        style={{ width: 'auto', height: '40px', objectFit: 'contain', marginRight: '10px' }}
                    />
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <Button variant="primary" onClick={handleAddTarget} className="me-3">Add</Button>
                    <Button variant="primary" onClick={handleSearchModalOpen} className="me-3">Search</Button>
                    <Button variant="primary" onClick={handleClearSearch} className="me-3">Clear</Button>
                    <Button variant="danger" onClick={handleLogout} className="ms-3">Logout</Button>
                </Col>
            </Row>
            <p style={{ color: targets.length === 0 ? 'red' : 'black', fontWeight: 'bold', textAlign: 'center' }}>
                {message}
            </p>
    
            <Table striped bordered hover responsive className="desktop-table">
                <thead>
                    <tr>
                        <th className="text-start">Name</th>
                        <th className="text-start">Company</th>
                        <th className="text-start">Designation</th>
                        <th className="text-start">Status</th>
                        <th className="text-start">Contact</th>
                        <th
                            className="text-start clickable-link"
                            onClick={() => requestSort('touchAge')}
                        >
                            Out Touch
                        </th>
                        <th
                            className="text-start clickable-link"
                            onClick={() => requestSort('reminder')}
                        >
                            Rmdr
                        </th>
                        <th
                            className="text-start clickable-link"
                            onClick={() => requestSort('dueTouch')}
                        >
                            Due Connect
                        </th>
                        <th className="text-start">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTargets.map((target) => (
                        <tr key={target.id}>
                            <td
                                className="clickable-link"
                                onClick={() => {
                                    setSelectedTargetId(target.id);
                                    setShowCommunicationModal(true);
                                }}
                            >
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {target.linkedinUrl ? (
                                        <a
                                            href={target.linkedinUrl.startsWith('http') ? target.linkedinUrl : `https://${target.linkedinUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaLinkedin style={{ color: 'blue', cursor: 'pointer' }} />
                                        </a>
                                    ) : (
                                        <FaLinkedin style={{ color: 'grey', cursor: 'default' }} />
                                    )}
                                </span>
                                <span style={{ marginLeft: '10px' }}>{target.firstName} {target.lastName}</span>
                            </td>
                            <td className="wrap-text text-start">{target.company}</td>
                            <td className="wrap-text text-start">{target.designation}</td>
                            <td className="text-start">{target.status.toLowerCase()}</td>
                            <td className="text-start">{target.phone}</td>
                            <td className="wrap-text text-start">
                                {target.touchAge !== '' ? (
                                    <span className={parseInt(target.touchAge, 10) <= -30 ? 'text-danger fw-bold' : ''}>
                                        {`${target.touchAge} days`}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </td>
                            <td>
                                <span
                                    className="bell-icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedTargetId(target.id);
                                        setShowReminderModal(true);
                                    }}
                                >
                                    <FaBell className="bell-icon-size" />
                                    {reminderCounts[target.id] > 0 && (
                                        <span className="reminder-count">
                                            {reminderCounts[target.id]}
                                        </span>
                                    )}
                                </span>
                            </td>
                            <td className="wrap-text text-start">
                                {target.dueTouch !== '' ? (
                                    <span className={parseInt(target.dueTouch, 10) > -5 && parseInt(target.dueTouch, 10) <= 3 ? 'text-danger fw-bold' : ''}>
                                        {`${target.dueTouch} days`}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </td>
                            <td className="text-start">
                                <FaEdit
                                    className="action-icon text-primary"
                                    onClick={(e) => handleEditTarget(target, e)}
                                />
                                {' '}
                                <FaTrash
                                    className="action-icon text-danger"
                                    onClick={() => handleDeleteTarget(target.id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
    
            <TargetForm
                show={showModal}
                handleClose={() => setShowModal(false)}
                targetToEdit={targetToEdit}
                fetchTargets={fetchTargets}
            />
    
            <Modal show={showCommunicationModal} onHide={handleCommunicationModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Communication History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTargetId && (
                        <CommunicationList
                            targetId={selectedTargetId}
                            handleCommunicationModalClose={handleCommunicationModalClose}
                        />
                    )}
                </Modal.Body>
            </Modal>
            <ReminderModal
                show={showReminderModal}
                handleClose={handleReminderModalClose}
                targetId={selectedTargetId}
            />
            <SearchModal
                show={showSearchModal}
                handleClose={handleSearchModalClose}
                handleSearch={handleSearch}
            />
        </div>
    );
    
    };
    
    export default TargetList;