// Pricing.js
import React from 'react';

function Pricing() {
  return (
    <div>
      <h1>Pricing Page</h1>
      <p>This is a test page for Pricing.</p>
    </div>
  );
}

export default Pricing;